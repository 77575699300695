import { Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { lambda_type, record_type, string_type, array_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DashboardQuery, InvoiceSpendOverTimeQuery as InvoiceSpendOverTimeQuery_2, InvoiceSpendOverTimeQuery_get_Default, PeriodicInvoiceBalance_$reflection, InvoiceSpendOverTimeQuery_$reflection, InvoiceTransportProductDetails_$reflection, InvoiceProductOverview_$reflection, InvoiceBalanceReport_$reflection, Account_$reflection, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Currency_formatCurrency, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { BillingIntentQuery, BillingIntentQuery_$reflection } from "../Models.fs.js";
import { tryFind, sumBy, map as map_1 } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "./SweetAlert.fs.js";
import { Chart_billingSpendDonutChartDonutChart, Chart_billingAllModesDonutChart, Chart_billingSpendOverTimeChart, Chart_billingCustomsSpendChart, Chart_billingWarehouseSpendChart, Chart_billingPastDueBalanceChart, Chart_billingCurrentBalanceChart } from "./Chart.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getInvoicesTransportSpendOvertime, getInvoicesSpendOvertime, getInvoiceBalanceReports } from "../Api/InvoiceApi.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { now, date, compare } from "../fable_modules/fable-library.4.10.0/Date.js";
import { defaultArg, value as value_439, ofNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { compare as compare_1, printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { render as render_1 } from "./Loader.fs.js";
import { op_Addition, fromParts } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import Decimal from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class BillingDashboards extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SpendOverTime", "Balance"];
    }
}

export function BillingDashboards_$reflection() {
    return union_type("CView.UI.BillingDashboard.BillingDashboard.BillingDashboards", [], BillingDashboards, () => [[], []]);
}

export class State extends Record {
    constructor(User, ActiveDashboard, Accounts, InvoicesBalancesReports, InvoicesSpendOverTimeReports, InvoicesTransportSpendOverTimeReport, InvoiceSpendOverTimeQuery) {
        super();
        this.User = User;
        this.ActiveDashboard = ActiveDashboard;
        this.Accounts = Accounts;
        this.InvoicesBalancesReports = InvoicesBalancesReports;
        this.InvoicesSpendOverTimeReports = InvoicesSpendOverTimeReports;
        this.InvoicesTransportSpendOverTimeReport = InvoicesTransportSpendOverTimeReport;
        this.InvoiceSpendOverTimeQuery = InvoiceSpendOverTimeQuery;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.BillingDashboard.BillingDashboard.State", [], State, () => [["User", User_$reflection()], ["ActiveDashboard", BillingDashboards_$reflection()], ["Accounts", array_type(Account_$reflection())], ["InvoicesBalancesReports", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [InvoiceBalanceReport_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", InvoiceBalanceReport_$reflection()]], [["ErrorValue", string_type]]]))], ["InvoicesSpendOverTimeReports", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [InvoiceProductOverview_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", InvoiceProductOverview_$reflection()]], [["ErrorValue", string_type]]]))], ["InvoicesTransportSpendOverTimeReport", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(InvoiceTransportProductDetails_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(InvoiceTransportProductDetails_$reflection())]], [["ErrorValue", string_type]]]))], ["InvoiceSpendOverTimeQuery", InvoiceSpendOverTimeQuery_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "BillingSearch"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.BillingDashboard.BillingDashboard.Intent", [], Intent, () => [[], [["Item", BillingIntentQuery_$reflection()]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedDashboard", "BarChartClicked", "GetInvoicesBalancesReports", "GetInvoicesSpendOverTimeReports", "GetInvoicesTransportSpendOverTimeReport", "SearchInvoicesByStatus", "UpdateInvoiceSpendOverTimeQuery", "InitializeDashboard", "UpdateQueryModeOfTransport"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.BillingDashboard.BillingDashboard.Msg", [], Msg, () => [[["Item", BillingDashboards_$reflection()]], [["Item", PeriodicInvoiceBalance_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [InvoiceBalanceReport_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", InvoiceBalanceReport_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [InvoiceProductOverview_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", InvoiceProductOverview_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(InvoiceTransportProductDetails_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(InvoiceTransportProductDetails_$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", lambda_type(InvoiceSpendOverTimeQuery_$reflection(), InvoiceSpendOverTimeQuery_$reflection())]], [], [["Item", string_type]]]);
}

const BillingCurrentBalanceChartId = "BillingCurrentBalanceChart";

const BillingPastDueBalanceChartId = "BillingPastDueBalanceChart";

const BillingWarehouseSpendChartId = "BillingWarehouseSpendChart";

const BillingCustomsSpendChartId = "BillingCustomsSpendChart";

const BillingSpendOverTimeChartId = "BillingSpendOverTimeChart";

const BillingAllModesDonutChartId = "BillingAllModesDonutChart";

const BillingAirSpendDonutChartId = "BillingAirSpendDonutChart";

const BillingOceanModesDonutChartId = "BillingOceanModesDonutChart";

const BillingGroundModesDonutChartId = "BillingGroundModesDonutChart";

const BillingRailModesDonutChartId = "BillingRailModesDonutChart";

export function init(user, accounts) {
    let bind$0040;
    return [new State(user, new BillingDashboards(0, []), accounts, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), (bind$0040 = InvoiceSpendOverTimeQuery_get_Default(), new InvoiceSpendOverTimeQuery_2(bind$0040.ModeOfTransport, bind$0040.Range, bind$0040.GroupBy, map_1((x) => x.Id, accounts)))), Cmd_none()];
}

export function update(msg, state) {
    let bind$0040, invoiceBalanceReport, invoiceSpendOverTimeReports, bind$0040_1, invoiceTransportSpendOverTimeReport, bind$0040_2;
    switch (msg.tag) {
        case 0:
            return [new State(state.User, msg.fields[0], state.Accounts, state.InvoicesBalancesReports, state.InvoicesSpendOverTimeReports, state.InvoicesTransportSpendOverTimeReport, state.InvoiceSpendOverTimeQuery), Cmd_none(), new Intent(0, [])];
        case 6:
            return [new State(state.User, state.ActiveDashboard, state.Accounts, state.InvoicesBalancesReports, state.InvoicesSpendOverTimeReports, state.InvoicesTransportSpendOverTimeReport, msg.fields[0](state.InvoiceSpendOverTimeQuery)), Cmd_batch(ofArray([singleton((dispatch_2) => {
                dispatch_2(new Msg(3, [new AsyncMsg$1(0, [])]));
            }), singleton((dispatch_3) => {
                dispatch_3(new Msg(4, [new AsyncMsg$1(0, [])]));
            })])), new Intent(0, [])];
        case 8:
            return [new State(state.User, state.ActiveDashboard, state.Accounts, state.InvoicesBalancesReports, state.InvoicesSpendOverTimeReports, state.InvoicesTransportSpendOverTimeReport, (bind$0040 = state.InvoiceSpendOverTimeQuery, new InvoiceSpendOverTimeQuery_2(msg.fields[0], bind$0040.Range, bind$0040.GroupBy, bind$0040.AccountIds))), singleton((dispatch_4) => {
                dispatch_4(new Msg(4, [new AsyncMsg$1(0, [])]));
            }), new Intent(0, [])];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, new Deferred$1(2, [msg.fields[0].fields[0]]), state.InvoicesSpendOverTimeReports, state.InvoicesTransportSpendOverTimeReport, state.InvoiceSpendOverTimeQuery), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Billing Balance Report", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((invoiceBalanceReport = msg.fields[0].fields[0].fields[0], Cmd_batch(ofArray([Chart_billingCurrentBalanceChart(BillingCurrentBalanceChartId, invoiceBalanceReport.CurrentBalances, (Item_2) => (new Msg(1, [Item_2]))), Chart_billingPastDueBalanceChart(BillingPastDueBalanceChartId, invoiceBalanceReport.HistoricalBalances, (Item_3) => (new Msg(1, [Item_3])))])))), new Intent(0, [])];
            }
            else if (equals(state.InvoicesBalancesReports, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, new Deferred$1(1, []), state.InvoicesSpendOverTimeReports, state.InvoicesTransportSpendOverTimeReport, state.InvoiceSpendOverTimeQuery), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, getInvoiceBalanceReports, new DashboardQuery(map_1((account) => account.Id, state.Accounts)), (arg) => (new Msg(2, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
            }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, state.InvoicesBalancesReports, new Deferred$1(2, [msg.fields[0].fields[0]]), state.InvoicesTransportSpendOverTimeReport, state.InvoiceSpendOverTimeQuery), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Billing Spending Over Time", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((invoiceSpendOverTimeReports = msg.fields[0].fields[0].fields[0], Cmd_batch(ofArray([Chart_billingWarehouseSpendChart(BillingWarehouseSpendChartId, invoiceSpendOverTimeReports.InvoiceNonTransportProductAmounts), Chart_billingCustomsSpendChart(BillingCustomsSpendChartId, invoiceSpendOverTimeReports.InvoiceNonTransportProductAmounts)])))), new Intent(0, [])];
            }
            else if (equals(state.InvoicesSpendOverTimeReports, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, state.InvoicesBalancesReports, new Deferred$1(1, []), state.InvoicesTransportSpendOverTimeReport, state.InvoiceSpendOverTimeQuery), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, getInvoicesSpendOvertime, (bind$0040_1 = state.InvoiceSpendOverTimeQuery, new InvoiceSpendOverTimeQuery_2(bind$0040_1.ModeOfTransport, bind$0040_1.Range, bind$0040_1.GroupBy, map_1((account_1) => account_1.Id, state.Accounts))), (arg_2) => (new Msg(3, [new AsyncMsg$1(1, [arg_2])]))), new Intent(0, [])];
            }
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, state.InvoicesBalancesReports, state.InvoicesSpendOverTimeReports, new Deferred$1(2, [msg.fields[0].fields[0]]), state.InvoiceSpendOverTimeQuery), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Billing Transport Spending Over Time", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((invoiceTransportSpendOverTimeReport = msg.fields[0].fields[0].fields[0], Cmd_batch(toList(delay(() => append(singleton_1(Chart_billingSpendOverTimeChart(BillingSpendOverTimeChartId, invoiceTransportSpendOverTimeReport)), delay(() => {
                    const matchValue = state.InvoiceSpendOverTimeQuery.ModeOfTransport;
                    return (matchValue === "") ? singleton_1(Chart_billingAllModesDonutChart(BillingAllModesDonutChartId, invoiceTransportSpendOverTimeReport)) : ((matchValue === "Air") ? singleton_1(Chart_billingSpendDonutChartDonutChart(BillingAirSpendDonutChartId, invoiceTransportSpendOverTimeReport)) : ((matchValue === "Ocean") ? singleton_1(Chart_billingSpendDonutChartDonutChart(BillingOceanModesDonutChartId, invoiceTransportSpendOverTimeReport)) : ((matchValue === "Ground") ? singleton_1(Chart_billingSpendDonutChartDonutChart(BillingGroundModesDonutChartId, invoiceTransportSpendOverTimeReport)) : singleton_1(Chart_billingSpendDonutChartDonutChart(BillingRailModesDonutChartId, invoiceTransportSpendOverTimeReport)))));
                }))))))), new Intent(0, [])];
            }
            else if (equals(state.InvoicesTransportSpendOverTimeReport, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.ActiveDashboard, state.Accounts, state.InvoicesBalancesReports, state.InvoicesSpendOverTimeReports, new Deferred$1(1, []), state.InvoiceSpendOverTimeQuery), Cmd_OfAsyncWith_perform((computation_2) => {
                    startImmediate(computation_2);
                }, getInvoicesTransportSpendOvertime, (bind$0040_2 = state.InvoiceSpendOverTimeQuery, new InvoiceSpendOverTimeQuery_2(bind$0040_2.ModeOfTransport, bind$0040_2.Range, bind$0040_2.GroupBy, map_1((account_2) => account_2.Id, state.Accounts))), (arg_4) => (new Msg(4, [new AsyncMsg$1(1, [arg_4])]))), new Intent(0, [])];
            }
        case 1: {
            const isCurrent = compare(date(msg.fields[0].StartDate), date(now())) >= 0;
            const isMaxPastDate = !isCurrent && !(msg.fields[0].EndDate != null);
            const invoiceQuery = new BillingIntentQuery(isCurrent ? "Current" : "Past Due", "Due Date", isMaxPastDate ? void 0 : msg.fields[0].StartDate, isMaxPastDate ? msg.fields[0].StartDate : ofNullable(msg.fields[0].EndDate));
            return [state, Cmd_none(), new Intent(1, [invoiceQuery])];
        }
        case 5: {
            const invoiceQuery_1 = new BillingIntentQuery(msg.fields[0], "", void 0, void 0);
            return [state, Cmd_none(), new Intent(1, [invoiceQuery_1])];
        }
        default:
            return [state, Cmd_batch(ofArray([singleton((dispatch) => {
                dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
            }), singleton((dispatch_1) => {
                dispatch_1(new Msg(4, [new AsyncMsg$1(0, [])]));
            })])), new Intent(0, [])];
    }
}

export function render(state, dispatch) {
    let elems_48, elems_15, elems_5, elems_4, elems_3, elems_1, elems, elems_2, elems_14, elems_7, elems_6, elems_9, elems_8, elems_11, elems_10, elems_13, elems_12, elems_38, elems_17, elems_16, elems_21, elems_20, elems_19, elems_18, elems_37, elems_47, elems_42, elems_41, children, elems_40, elems_46, elems_45, elems_44, elems_66, elems_65, elems_55, elems_50, elems_49, elems_52, elems_51, elems_54, elems_53, elems_64, elems_59, elems_58, elems_57, elems_63, elems_62, elems_61, elems_71, elems_69, children_2, elems_67, elems_68;
    const spendOverTimeTab = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("tab"), delay(() => (!equals(state.ActiveDashboard, new BillingDashboards(0, [])) ? singleton_1("is-hidden") : empty()))))))], ["id", "spend_over_time"], (elems_48 = [createElement("div", createObj(ofArray([["className", "block"], (elems_15 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_5 = [createElement("div", createObj(ofArray([["className", "column"], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-justify-content-left", "is-align-content-center", "is-align-items-center", "mb-2"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "px-0"])], (elems_1 = [createElement("select", createObj(ofArray([["className", "my-0"], (elems = [createElement("option", {
        value: "Year",
        children: "Last 12 Months",
    }), createElement("option", {
        value: "PriorYear",
        children: "Last 13 - 24 Months",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]), ["onChange", (e) => {
        const element = e.target;
        dispatch(new Msg(6, [(invoiceSpendOverTimeQuery) => (new InvoiceSpendOverTimeQuery_2(invoiceSpendOverTimeQuery.ModeOfTransport, element.value, invoiceSpendOverTimeQuery.GroupBy, invoiceSpendOverTimeQuery.AccountIds))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("span", {
        className: "mx-2",
        children: "By",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "has-addons"])], (elems_2 = [createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.GroupBy === "Week") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesSpendOverTimeReports, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        type: "button",
        children: "Week",
        onClick: (_arg) => {
            dispatch(new Msg(6, [(invoiceSpendOverTimeQuery_1) => (new InvoiceSpendOverTimeQuery_2(invoiceSpendOverTimeQuery_1.ModeOfTransport, invoiceSpendOverTimeQuery_1.Range, "Week", invoiceSpendOverTimeQuery_1.AccountIds))]));
        },
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.GroupBy === "Month") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesSpendOverTimeReports, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        type: "button",
        children: "Month",
        onClick: (_arg_1) => {
            dispatch(new Msg(6, [(invoiceSpendOverTimeQuery_2) => (new InvoiceSpendOverTimeQuery_2(invoiceSpendOverTimeQuery_2.ModeOfTransport, invoiceSpendOverTimeQuery_2.Range, "Month", invoiceSpendOverTimeQuery_2.AccountIds))]));
        },
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.GroupBy === "Quarter") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesSpendOverTimeReports, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        type: "button",
        children: "Quarter",
        onClick: (_arg_2) => {
            dispatch(new Msg(6, [(invoiceSpendOverTimeQuery_3) => (new InvoiceSpendOverTimeQuery_2(invoiceSpendOverTimeQuery_3.ModeOfTransport, invoiceSpendOverTimeQuery_3.Range, "Quarter", invoiceSpendOverTimeQuery_3.AccountIds))]));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_14 = [createElement("div", createObj(ofArray([["className", "column"], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "has-background-primary"])], ["style", {
        minHeight: 12 + "rem",
    }], (elems_6 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "has-text-white", "is-size-5"]),
        children: "Total Spend",
    })), delay(() => {
        const matchValue = state.InvoicesSpendOverTimeReports;
        let matchResult, invoiceSpendOverTimeReports;
        switch (matchValue.tag) {
            case 1: {
                matchResult = 0;
                break;
            }
            case 2: {
                if (matchValue.fields[0].tag === 0) {
                    matchResult = 1;
                    invoiceSpendOverTimeReports = matchValue.fields[0].fields[0];
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            default:
                matchResult = 2;
        }
        switch (matchResult) {
            case 0:
                return singleton_1(render_1());
            case 1: {
                const totalSum = sumBy((x_1) => {
                    if (x_1.AmountUsd != null) {
                        return value_439(x_1.AmountUsd);
                    }
                    else {
                        return fromParts(0, 0, 0, false, 0);
                    }
                }, invoiceSpendOverTimeReports.InvoiceProductMetrics.filter((x) => (x.AmountUsd != null)), {
                    GetZero: () => (new Decimal("0")),
                    Add: op_Addition,
                });
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-4", "has-text-centered", "has-text-weight-semibold", "has-text-white"])], ((fmt = printf("%s USD"), fmt.cont((value_64) => ["children", value_64])))(Currency_formatCurrency("en-US", "USD", totalSum))]))));
                }));
            }
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "has-background-link"])], ["style", {
        minHeight: 12 + "rem",
    }], (elems_8 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "has-text-white", "is-size-5"]),
        children: "Transportation",
    })), delay(() => {
        const matchValue_1 = state.InvoicesSpendOverTimeReports;
        let matchResult_1, invoiceSpendOverTimeReports_1;
        switch (matchValue_1.tag) {
            case 1: {
                matchResult_1 = 0;
                break;
            }
            case 2: {
                if (matchValue_1.fields[0].tag === 0) {
                    matchResult_1 = 1;
                    invoiceSpendOverTimeReports_1 = matchValue_1.fields[0].fields[0];
                }
                else {
                    matchResult_1 = 2;
                }
                break;
            }
            default:
                matchResult_1 = 2;
        }
        switch (matchResult_1) {
            case 0:
                return singleton_1(render_1());
            case 1: {
                const transportationSum = sumBy((x_4) => {
                    if (x_4.AmountUsd != null) {
                        return value_439(x_4.AmountUsd);
                    }
                    else {
                        return fromParts(0, 0, 0, false, 0);
                    }
                }, invoiceSpendOverTimeReports_1.InvoiceProductMetrics.filter((x_3) => {
                    if (x_3.AmountUsd != null) {
                        return x_3.IsTransportation;
                    }
                    else {
                        return false;
                    }
                }), {
                    GetZero: () => (new Decimal("0")),
                    Add: op_Addition,
                });
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt_1;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-4", "has-text-centered", "has-text-weight-semibold", "has-text-white"])], ((fmt_1 = printf("%s USD"), fmt_1.cont((value_80) => ["children", value_80])))(Currency_formatCurrency("en-US", "USD", transportationSum))]))));
                }));
            }
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "has-background-link"])], ["style", {
        minHeight: 12 + "rem",
    }], (elems_10 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "has-text-white", "is-size-5"]),
        children: "Warehouse",
    })), delay(() => {
        const matchValue_2 = state.InvoicesSpendOverTimeReports;
        let matchResult_2, invoiceSpendOverTimeReports_2;
        switch (matchValue_2.tag) {
            case 1: {
                matchResult_2 = 0;
                break;
            }
            case 2: {
                if (matchValue_2.fields[0].tag === 0) {
                    matchResult_2 = 1;
                    invoiceSpendOverTimeReports_2 = matchValue_2.fields[0].fields[0];
                }
                else {
                    matchResult_2 = 2;
                }
                break;
            }
            default:
                matchResult_2 = 2;
        }
        switch (matchResult_2) {
            case 0:
                return singleton_1(render_1());
            case 1: {
                const warehouseSum = sumBy((x_7) => {
                    if (x_7.AmountUsd != null) {
                        return value_439(x_7.AmountUsd);
                    }
                    else {
                        return fromParts(0, 0, 0, false, 0);
                    }
                }, invoiceSpendOverTimeReports_2.InvoiceProductMetrics.filter((x_6) => {
                    if (x_6.AmountUsd != null) {
                        return x_6.Product === "Warehouse";
                    }
                    else {
                        return false;
                    }
                }), {
                    GetZero: () => (new Decimal("0")),
                    Add: op_Addition,
                });
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt_2;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-4", "has-text-centered", "has-text-weight-semibold", "has-text-white"])], ((fmt_2 = printf("%s USD"), fmt_2.cont((value_96) => ["children", value_96])))(Currency_formatCurrency("en-US", "USD", warehouseSum))]))));
                }));
            }
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "has-background-link"])], ["style", {
        minHeight: 12 + "rem",
    }], (elems_12 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "has-text-white", "is-size-5"]),
        children: "Customs Brokerage",
    })), delay(() => {
        const matchValue_3 = state.InvoicesSpendOverTimeReports;
        let matchResult_3, invoiceSpendOverTimeReports_3;
        switch (matchValue_3.tag) {
            case 1: {
                matchResult_3 = 0;
                break;
            }
            case 2: {
                if (matchValue_3.fields[0].tag === 0) {
                    matchResult_3 = 1;
                    invoiceSpendOverTimeReports_3 = matchValue_3.fields[0].fields[0];
                }
                else {
                    matchResult_3 = 2;
                }
                break;
            }
            default:
                matchResult_3 = 2;
        }
        switch (matchResult_3) {
            case 0:
                return singleton_1(render_1());
            case 1: {
                const customBrokerageSum = sumBy((x_10) => {
                    if (x_10.AmountUsd != null) {
                        return value_439(x_10.AmountUsd);
                    }
                    else {
                        return fromParts(0, 0, 0, false, 0);
                    }
                }, invoiceSpendOverTimeReports_3.InvoiceProductMetrics.filter((x_9) => {
                    if (x_9.AmountUsd != null) {
                        return x_9.Product === "Customs Brokerage";
                    }
                    else {
                        return false;
                    }
                }), {
                    GetZero: () => (new Decimal("0")),
                    Add: op_Addition,
                });
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt_3;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-4", "has-text-centered", "has-text-weight-semibold", "has-text-white"])], ((fmt_3 = printf("%s USD"), fmt_3.cont((value_112) => ["children", value_112])))(Currency_formatCurrency("en-US", "USD", customBrokerageSum))]))));
                }));
            }
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", "box"], ["style", {
        minHeight: 450 + "px",
    }], (elems_38 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_17 = [createElement("div", createObj(ofArray([["className", "column"], (elems_16 = [createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "is-size-5"]),
        children: "Transportation",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_21 = [createElement("div", createObj(ofArray([["className", "column"], (elems_20 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between"])], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "has-addons"])], (elems_18 = [createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.ModeOfTransport === "") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesTransportSpendOverTimeReport, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        onClick: (_arg_3) => {
            dispatch(new Msg(8, [""]));
        },
        type: "button",
        children: "All",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.ModeOfTransport === "Air") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesTransportSpendOverTimeReport, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        onClick: (_arg_4) => {
            dispatch(new Msg(8, ["Air"]));
        },
        type: "button",
        children: "Air ",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.ModeOfTransport === "Ocean") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesTransportSpendOverTimeReport, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        onClick: (_arg_5) => {
            dispatch(new Msg(8, ["Ocean"]));
        },
        type: "button",
        children: "Ocean ",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.ModeOfTransport === "Ground") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesTransportSpendOverTimeReport, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        onClick: (_arg_6) => {
            dispatch(new Msg(8, ["Ground"]));
        },
        type: "button",
        children: "Ground",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => ((state.InvoiceSpendOverTimeQuery.ModeOfTransport === "Rail") ? append(singleton_1("is-primary"), delay(() => (equals(state.InvoicesTransportSpendOverTimeReport, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))) : empty())))))))),
        onClick: (_arg_7) => {
            dispatch(new Msg(8, ["Rail"]));
        },
        type: "button",
        children: "Rail",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_37 = toList(delay(() => {
        let elems_24, elems_23, elems_22;
        const matchValue_4 = state.InvoicesTransportSpendOverTimeReport;
        let matchResult_4, invoiceTransportSpendOverTimeReport;
        switch (matchValue_4.tag) {
            case 2: {
                if (matchValue_4.fields[0].tag === 0) {
                    matchResult_4 = 0;
                    invoiceTransportSpendOverTimeReport = matchValue_4.fields[0].fields[0];
                }
                else {
                    matchResult_4 = 2;
                }
                break;
            }
            case 1: {
                matchResult_4 = 1;
                break;
            }
            default:
                matchResult_4 = 2;
        }
        switch (matchResult_4) {
            case 0:
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-align-item-center", "is-two-thirds"])], (elems_24 = [createElement("div", createObj(ofArray([["className", "block"], (elems_23 = [createElement("div", createObj(ofArray([["className", "chart-container"], (elems_22 = [createElement("canvas", {
                    id: BillingSpendOverTimeChartId,
                    className: "c-view-chart",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))), delay(() => {
                    let elems_36, elems_35, elems_26, elems_25, elems_28, elems_27, elems_30, elems_29, elems_32, elems_31, elems_34, elems_33;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems_36 = [createElement("div", createObj(ofArray([["className", "block"], (elems_35 = [createElement("div", createObj(ofArray([["id", "all_modes_donut_chart"], ["className", (state.InvoiceSpendOverTimeQuery.ModeOfTransport !== "") ? "is-hidden" : ""], (elems_26 = [createElement("div", createObj(ofArray([["className", "chart-container"], (elems_25 = [createElement("canvas", {
                        id: BillingAllModesDonutChartId,
                        className: "c-view-chart",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("div", createObj(ofArray([["id", "air_donut_chart"], ["className", (state.InvoiceSpendOverTimeQuery.ModeOfTransport !== "Air") ? "is-hidden" : ""], (elems_28 = [createElement("div", createObj(ofArray([["className", "chart-container"], (elems_27 = [createElement("canvas", {
                        id: BillingAirSpendDonutChartId,
                        className: "c-view-chart",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))), createElement("div", createObj(ofArray([["id", "ocean_donut_chart"], ["className", (state.InvoiceSpendOverTimeQuery.ModeOfTransport !== "Ocean") ? "is-hidden" : ""], (elems_30 = [createElement("div", createObj(ofArray([["className", "chart-container"], (elems_29 = [createElement("canvas", {
                        id: BillingOceanModesDonutChartId,
                        className: "c-view-chart",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])]))), createElement("div", createObj(ofArray([["id", "ground_donut_chart"], ["className", (state.InvoiceSpendOverTimeQuery.ModeOfTransport !== "Ground") ? "is-hidden" : ""], (elems_32 = [createElement("div", createObj(ofArray([["className", "chart-container"], (elems_31 = [createElement("canvas", {
                        id: BillingGroundModesDonutChartId,
                        className: "c-view-chart",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])]))), createElement("div", createObj(ofArray([["id", "rail_donut_chart"], ["className", (state.InvoiceSpendOverTimeQuery.ModeOfTransport !== "Rail") ? "is-hidden" : ""], (elems_34 = [createElement("div", createObj(ofArray([["className", "chart-container"], (elems_33 = [createElement("canvas", {
                        id: BillingRailModesDonutChartId,
                        className: "c-view-chart",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])]))));
                }));
            case 1:
                return singleton_1(render_1());
            default:
                return singleton_1(defaultOf());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))), createElement("div", createObj(ofArray([["className", "columns"], ["style", {
        minHeight: 350 + "px",
    }], (elems_47 = [createElement("div", createObj(ofArray([["className", "column"], (elems_42 = [createElement("div", createObj(ofArray([["className", "box"], ["id", "warehouse_spend"], (elems_41 = [(children = ofArray([createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "is-size-5"]),
        children: "Warehouse",
    }), createElement("div", createObj(ofArray([["className", "chart-container"], (elems_40 = toList(delay(() => {
        let elems_39;
        return equals(state.InvoicesSpendOverTimeReports, new Deferred$1(1, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", "c-view-chart"], (elems_39 = [render_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))) : singleton_1(createElement("canvas", {
            id: BillingWarehouseSpendChartId,
            className: "c-view-chart",
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])))]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_46 = [createElement("div", createObj(ofArray([["className", "box"], ["id", "customs_brokerage_spend"], (elems_45 = [createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "is-size-5"]),
        children: "Customs Brokerage",
    }), createElement("div", createObj(ofArray([["className", "chart-container"], (elems_44 = toList(delay(() => {
        let elems_43;
        return equals(state.InvoicesSpendOverTimeReports, new Deferred$1(1, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", "c-view-chart"], (elems_43 = [render_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])))) : singleton_1(createElement("canvas", {
            id: BillingCustomsSpendChartId,
            className: "c-view-chart",
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])])));
    const balanceTab = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("tab"), delay(() => (!equals(state.ActiveDashboard, new BillingDashboards(1, [])) ? singleton_1("is-hidden") : empty()))))))], ["id", "balance"], (elems_66 = [createElement("div", createObj(ofArray([["className", "block"], (elems_65 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_55 = [createElement("div", createObj(ofArray([["className", "column"], (elems_50 = [createElement("div", createObj(ofArray([["className", "box"], ["style", {
        minHeight: 15 + "rem",
    }], (elems_49 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-4", "has-text-link"]),
        children: "Current",
    })), delay(() => {
        const matchValue_5 = state.InvoicesBalancesReports;
        let matchResult_5, invoicesBalancesReports;
        switch (matchValue_5.tag) {
            case 1: {
                matchResult_5 = 0;
                break;
            }
            case 2: {
                if (matchValue_5.fields[0].tag === 0) {
                    matchResult_5 = 1;
                    invoicesBalancesReports = matchValue_5.fields[0].fields[0];
                }
                else {
                    matchResult_5 = 2;
                }
                break;
            }
            default:
                matchResult_5 = 2;
        }
        switch (matchResult_5) {
            case 0:
                return singleton_1(render_1());
            case 1:
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt_4, input_1;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-3", "has-text-centered", "has-text-weight-semibold", "has-text-link", "is-clickable"])], ((fmt_4 = printf("%s USD"), fmt_4.cont((value_324) => ["children", value_324])))(Currency_formatCurrency("en-US", "USD", defaultArg((input_1 = tryFind((x_12) => (compare_1(x_12.Status, "Current", 5) === 0), invoicesBalancesReports.BalancesByStatus), (input_1 == null) ? void 0 : input_1.Amount), fromParts(0, 0, 0, false, 0)))), ["onClick", (_arg_8) => {
                        dispatch(new Msg(5, ["Current"]));
                    }]]))));
                }));
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_52 = [createElement("div", createObj(ofArray([["className", "box"], ["style", {
        minHeight: 15 + "rem",
    }], (elems_51 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-4", "has-text-danger"]),
        children: "Past Due",
    })), delay(() => {
        const matchValue_6 = state.InvoicesBalancesReports;
        let matchResult_6, invoicesBalancesReports_1;
        switch (matchValue_6.tag) {
            case 1: {
                matchResult_6 = 0;
                break;
            }
            case 2: {
                if (matchValue_6.fields[0].tag === 0) {
                    matchResult_6 = 1;
                    invoicesBalancesReports_1 = matchValue_6.fields[0].fields[0];
                }
                else {
                    matchResult_6 = 2;
                }
                break;
            }
            default:
                matchResult_6 = 2;
        }
        switch (matchResult_6) {
            case 0:
                return singleton_1(render_1());
            case 1:
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt_5, input_3;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-3", "has-text-centered", "has-text-weight-semibold", "has-text-danger", "is-clickable"])], ((fmt_5 = printf("%s USD"), fmt_5.cont((value_343) => ["children", value_343])))(Currency_formatCurrency("en-US", "USD", defaultArg((input_3 = tryFind((x_14) => (compare_1(x_14.Status, "Past Due", 5) === 0), invoicesBalancesReports_1.BalancesByStatus), (input_3 == null) ? void 0 : input_3.Amount), fromParts(0, 0, 0, false, 0)))), ["onClick", (_arg_9) => {
                        dispatch(new Msg(5, ["Past Due"]));
                    }]]))));
                }));
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_51))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_52))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_54 = [createElement("div", createObj(ofArray([["className", "box"], ["style", {
        minHeight: 15 + "rem",
    }], (elems_53 = toList(delay(() => append(singleton_1(createElement("h6", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-4", "has-text-primary"]),
        children: "Paid",
    })), delay(() => {
        const matchValue_7 = state.InvoicesBalancesReports;
        let matchResult_7, invoicesBalancesReports_2;
        switch (matchValue_7.tag) {
            case 1: {
                matchResult_7 = 0;
                break;
            }
            case 2: {
                if (matchValue_7.fields[0].tag === 0) {
                    matchResult_7 = 1;
                    invoicesBalancesReports_2 = matchValue_7.fields[0].fields[0];
                }
                else {
                    matchResult_7 = 2;
                }
                break;
            }
            default:
                matchResult_7 = 2;
        }
        switch (matchResult_7) {
            case 0:
                return singleton_1(render_1());
            case 1:
                return append(singleton_1(createElement("hr", {})), delay(() => {
                    let fmt_6, input_5;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-size-3", "has-text-centered", "has-text-weight-semibold", "has-text-primary", "is-clickable"])], ((fmt_6 = printf("%s USD"), fmt_6.cont((value_362) => ["children", value_362])))(Currency_formatCurrency("en-US", "USD", defaultArg((input_5 = tryFind((x_16) => (compare_1(x_16.Status, "Paid", 5) === 0), invoicesBalancesReports_2.BalancesByStatus), (input_5 == null) ? void 0 : input_5.Amount), fromParts(0, 0, 0, false, 0)))), ["onClick", (_arg_10) => {
                        dispatch(new Msg(5, ["Paid"]));
                    }]]))));
                }));
            default:
                return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_53))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_54))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_55))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_64 = [createElement("div", createObj(ofArray([["className", "column"], (elems_59 = [createElement("div", createObj(ofArray([["className", "box"], ["id", "due_soon_billing_chart"], (elems_58 = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-4"]),
        children: "Current - By Due Date",
    }), createElement("div", createObj(ofArray([["className", "chart-container"], (elems_57 = toList(delay(() => {
        let elems_56;
        return equals(state.InvoicesBalancesReports, new Deferred$1(1, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", "c-view-chart"], (elems_56 = [render_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_56))])])))) : singleton_1(createElement("canvas", {
            id: BillingCurrentBalanceChartId,
            className: "c-view-chart",
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_57))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_58))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_59))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_63 = [createElement("div", createObj(ofArray([["className", "box"], ["id", "past_due_billing_chart"], (elems_62 = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "has-text-danger", "is-size-4"]),
        children: "Past Due",
    }), createElement("div", createObj(ofArray([["className", "chart-container"], (elems_61 = toList(delay(() => {
        let elems_60;
        return equals(state.InvoicesBalancesReports, new Deferred$1(1, [])) ? singleton_1(createElement("div", createObj(ofArray([["className", "c-view-chart"], (elems_60 = [render_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_60))])])))) : singleton_1(createElement("canvas", {
            id: BillingPastDueBalanceChartId,
            className: "c-view-chart",
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_61))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_62))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_63))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_64))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_65))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_66))])])));
    return createElement("div", createObj(ofArray([["className", "tab"], ["id", "billing_dashboards"], (elems_71 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-toggle"])], (elems_69 = [(children_2 = ofArray([createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveDashboard, new BillingDashboards(0, [])) ? singleton_1("is-active") : empty()))))))))], (elems_67 = [createElement("a", {
        onClick: (_arg_11) => {
            dispatch(new Msg(0, [new BillingDashboards(0, [])]));
            dispatch(new Msg(6, [(invoiceSpendOverTimeQuery_4) => InvoiceSpendOverTimeQuery_get_Default()]));
            dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
        },
        children: "Spend Over Time",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_67))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveDashboard, new BillingDashboards(1, [])) ? singleton_1("is-active") : empty()))))))))], (elems_68 = [createElement("a", {
        onClick: (_arg_12) => {
            dispatch(new Msg(0, [new BillingDashboards(1, [])]));
            dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
        },
        children: "Balance",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_68))])])))]), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_69))])]))), createElement("div", {
        className: "tabcontainer",
        id: "billing_tabcontent",
        children: Interop_reactApi.Children.toArray([spendOverTimeTab, balanceTab]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_71))])])));
}

